<template>
  <div class="my-3 p-3 bg-light rounded">
    <span v-for="categs in catList" :key="categs.id">
      <a :href="'/category/'+categs.id" class="me-1">
        <span class="badge bg-dark bg-gradient rounded-pill mt-2">{{ categs.titulo }}</span>
      </a>
    </span>
  </div>
</template>

<script>
import { URL_BASE } from "../services/Commons";
import CategoriaService from "../services/categoria.service";
export default {
  name: "CategoryComponent",
  props: [],
  data: () => ({
    catList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    CategoriaService.getAll().then((result) => {
      if (result.status < 400) {
        this.catList = result.data;
      }
    });
  },
};
</script>