<template>
  <div>
    <main class="container">
      <link-component></link-component>

      <div class="row g-5">
        <div class="col-md-12">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                {{ categoriaName(this.id) }}
              </li>
            </ol>
          </nav>

          <h4 class="pb-4 mb-4 fst-italic border-bottom text-center">
            Publicações da categoria:
            <span class="badge bg-dark rounded-pill">{{
              categoriaName(this.id)
            }}</span>
          </h4>

          <div class="row row-cols-1 row-cols-md-3 g-4 mb-5">
            <div
              class="col"
              v-for="estudoscat in estudoList"
              :key="estudoscat.id"
            >
              <article class="blog-post p-4 mb-2 bg-light rounded">
                <img
                  v-if="estudoscat.image_path"
                  :src="urlImgEstudo + estudoscat.id"
                  class="img-fluid rounded"
                  :alt="estudoscat.titulo"
                  :title="estudoscat.titulo"
                />
                <img
                  v-else
                  src="../../assets/images/semimagem.jpg"
                  class="img-fluid rounded"
                  :alt="estudoscat.titulo"
                  :title="estudoscat.titulo"
                />

                <h4 class="fst-italic mt-2">{{ estudoscat.titulo }}</h4>
                <p class="blog-post-meta" v-if="moment">
                  <i class="bi bi-calendar3"></i>
                  {{ moment(estudoscat.updated_at).format("DD MMMM YYYY") }}
                </p>

                <span v-html="estudoscat.subtitulo"></span>
                <nav class="blog-pagination mt-2" aria-label="Pagination">
                  <a
                    class="btn btn-outline-primary"
                    :href="'/ler/estudo/' + estudoscat.id"
                    >Saiba mais...</a
                  >
                </nav>
              </article>
            </div>
          </div>

          <category-component style="font-size: 1.3em"></category-component>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <apoio-component></apoio-component>
      </div>
    </main>
  </div>
</template>

<script>
import { URL_BASE } from "../../services/Commons";
import EstudoService from "../../services/estudo.service";
import CategoriaService from "../../services/categoria.service";
import apoioComponent from "../../components/apoioComponent.vue";
import linkComponent from "../../components/linkComponent.vue";
import categoryComponent from "../../components/categoryComponent.vue";
import moment from "moment";
import { useRoute } from "vue-router";
import { event } from 'vue-gtag';
export default {
  name: "ListarCategory",
  data: () => ({
    categoriaList: [],
    estudoList: [],
    urlBase: "",
    id: null,
    urlImgEstudo: "",
    moment: null,
  }),
  components: {
    apoioComponent,
    linkComponent,
    categoryComponent,
  },
  mounted() {
    event('entrou_listar_artigos', { method: 'Google' })
    this.urlBase = URL_BASE;
    this.urlImgEstudo = URL_BASE + `estudodowimagem/`;

    this.moment = moment;
    this.moment.locale("pt-br");

    const route = useRoute();
    this.id = route.params.id;

    EstudoService.getPaginaCategoria(this.id, 0, 30).then((result) => {
      if (result.status < 400) {
        this.estudoList = result.data;
      }
    });

    CategoriaService.getAll().then((result) => {
      if (result.status < 400) {
        this.categoriaList = result.data;
      }
    });
  },
  methods: {
    categoriaName(idcateg) {
      let retVal = "";
      this.categoriaList.forEach((categ) => {
        if (categ.id == idcateg) {
          retVal = categ.titulo;
        }
      });
      return retVal;
    },
  },
};
</script>

<style>
</style>
